export default {
  init() {
    const $body = document.getElementsByTagName('body')[0];
    const $navPrimary = document.querySelectorAll('.nav-primary')[0];
    const $menuButton = document.getElementById('menubutton');

    // region Accessible mobile menu (dialog)
    function showMenu() {
      $body.classList.add('heromodal');
      $navPrimary.setAttribute('role', 'dialog');
      $navPrimary.setAttribute('tabIndex', -1); // allow programmatic focus on nav section
      $menuButton.setAttribute('aria-expanded', 'true');
      $menuButton.setAttribute('aria-label', 'hoofdmenu sluiten');
      lockOverlay();
    }

    function hideMenu() {
      $body.classList.remove('heromodal');
      $navPrimary.removeAttribute('role');
      $navPrimary.removeAttribute('tabIndex');
      $menuButton.setAttribute('aria-expanded', 'false');
      $menuButton.setAttribute('aria-label', 'hoofdmenu openen');
      unLockOverlay();
    }

    function toggleMenu(event) {
      const wasExpanded = event.currentTarget.getAttribute('aria-expanded');
      if (wasExpanded === 'true') {
        hideMenu();
      } else {
        showMenu();
      }
    }

    /**
     * Allow to always quit the menu with ESC key
     */
    $navPrimary.addEventListener("keydown", (event) => {//when this happens
      if (event.code === 'Escape') {
        hideMenu();
      }
    });

    $menuButton.addEventListener('click', function (event) {
      event.preventDefault();
      toggleMenu(event);
    }, false);

    /**
     * Handle toggling the (mobile) menu with keyboard shortcuts
     * @param event
     */
    function handleKeyDown(event) {
      switch (event.code) {
        case 'Enter':
        case 'Space': {
          event.preventDefault();
          toggleMenu(event);
          break;
        }
        case 'Escape': {
          event.preventDefault();
          event.stopPropagation();
          break;
        }
      }
    }

    // $menuButton.addEventListener('keydown', handleKeyDown);
    $menuButton.addEventListener("keydown", (event) => {//when this happens
      handleKeyDown(event);
    });

    function setFocusToOverlay() {
      $navPrimary.focus();
    }

    function handleFocus(event) {
      if ($navPrimary.querySelectorAll(event.target.classList).length >- 1) {
        event.preventDefault();
        setFocusToOverlay();
      }
    }

    function lockOverlay() {
      document.addEventListener('focus', handleFocus, true);
    }

    function unLockOverlay() {
      document.removeEventListener('focus', handleFocus, true);
    }
    // endregion
  },
};
