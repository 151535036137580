const setScollY = (y) => {
  if( !y) {
    y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  }

  document.documentElement.style.setProperty('--scroll-y', parseInt(y || '0')+'px');
}

export const initScoll = () => {
  let topElem = document.getElementById('topnav');

  const onScroll = function() {
    var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    setScollY(y);

    if (y >= 50) {
      topElem.classList.add('stick');
    }
    else {
      topElem.classList.remove('stick');
    }
  };

  window.removeEventListener('scroll', onScroll);
  window.addEventListener('scroll', onScroll);

  // more to come??
}
