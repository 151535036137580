"use strict"

import {domReady} from '@roots/sage/client';

import navToggle from './util/navToggle';
import { initScoll } from './util/scroll';

const initPage = () => {

  navToggle.init();

  // common
  initScoll();
}


/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  initPage();
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
